import React, { useState, Suspense, lazy, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation, useNavigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './css/index.css';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';

const CounselorApp = lazy(() => import('./counselor/CounselorApp'));
const App = lazy(() => import('./js/App'));
const Registoration = lazy(() => import('./js/Registration'));
const serverUrl = process.env.REACT_APP_API_SERVER;

const Root = () => (
  <Router>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/main" element={<Main />} />
      <Route path="/registoration" element={<Registoration/>}/>
    </Routes>
  </Router>
);

function Main(){
  const [data, setData] = useState(null);
  const location = useLocation();
  const [isManager, setIsManager] = useState(null); 
  const [isAdmin, setIsAdmin] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const { isManager: locationIsManager } = location.state || {};
    const { isAdmin: locationIsAdmin } = location.state || {};
    if (locationIsManager !== undefined) {
      setIsManager(locationIsManager);
      if(locationIsAdmin !== undefined){
        setIsAdmin(locationIsAdmin);
      }
    } else {
      axios.get(`${serverUrl}/auth/token/isManager`, { withCredentials: true })
        .then(response => {
          console.log(response.data.message);
          if (response.data.message === 'is manager') {
            setIsManager(true);
          } else if (response.data.message === 'is admin'){
            setIsManager(true);
            setIsAdmin(true);
          } else {
            setIsManager(false);
          }
        })
        .catch(error => {
          console.error('서버 요청 중 에러 발생:', error);
          navigate('/');
        });
    }
  }, [location.state, serverUrl]);

  useEffect(() => {
    const handleMessage = (event) => {
      console.log('Received message:', event.data);
      if (event.data.type === 'CHATROOM') {
        const { data } = event.data;
        setData(data);
      }
    };

    //navigator.serviceWorker.addEventListener('message', handleMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage);
    };
  }, []);
  if (isManager === null) {
    return <div>로딩 중...</div>;
  }

  if(isManager === true){
    return <App data={data} setData={setData} isAdmin={isAdmin}/>
  }else{
    return <CounselorApp data={data} setData={setData}/>
  }
}

function LoginPage({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem('token') || null);
  const [counselorMode, setCounselorMode] = useState(false);
  const topMainLogo = 'https://mjsoft.co/html/millennium/images/common/topmenu_logo_2.png';
  const [company, setCompany] = useState('');
  const [companyInfo, setCompanyInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectCompany, setSelectCompany] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios.post(serverUrl + "/auth/login", { withCredentials: true }, {
      withCredentials: true
    }).then(response => {
      console.log('응답 받음:', response.data.message);
      setLoginStatus(response.data.message);
      if (response.data.message === 'login') {
        navigate("/main", {state: {isManager: true, isAdmin: true}});
      }
    })
      .catch(error => {
        axios.post(serverUrl + "/auth/counselor/login", {withCredentials: true}, {withCredentials: true})
        .then(response => {
          setLoginStatus(response.data.message);
          if(response.data.message === 'login'){
            navigate("/main", {state: {isManager: false}});
          }else if(response.data.message === 'manager login'){
            navigate("/main", {state: {isManager: true}});
          }
        })
        .catch(error => {
          console.error('로그인 실패', error);
          setLoginStatus(null);
        });
      });

  }, []);

  useEffect(() => {
    if (token) {
      navigate("/main");
    }
  }, [token]);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(serverUrl + '/counselor/company' + `?search=${company}`);
      setCompanyInfo(response.data);
    } catch (error) {
      console.error('Error fetching chat data:', error);
    }
  };

  const handleLogin = () => {
    if (counselorMode) {
      axios.post(serverUrl + '/auth/counselor/login', {
        company: selectCompany.id,
        id: username,
        password: password
      }, { withCredentials: true })
        .then(response => {
          console.log('응답 받음:', response.data.message);
          setLoginStatus(response.data.message);
          if (response.data.message === 'login') {
            navigate("/main", {state: {isManager: false}});
          }else if(response.data.message === 'manager login'){
            navigate("/main", {state: {isManager: true}});
          }
        })
        .catch(error => {
          console.error('에러 발생:', error);
          setLoginStatus(false);
        });
    } else {
      axios.post(serverUrl + '/auth/login', {
        user_id: username,
        password: password
      }, { withCredentials: true, })
        .then(response => {
          console.log('응답 받음:', response.data.message);
          setLoginStatus(response.data.message);
          if (response.data.message === 'login') {
            navigate("/main", {state: {isManager: true, isAdmin: true}});
          }
        })
        .catch(error => {
          console.error('에러 발생:', error);
          setLoginStatus(false);
        });
    }
  };

  const openSearch = () => (
    <div className='modal'>
      <div className='bar'>
        <button onClick={() => setModalOpen(false)}>X</button>
      </div>
      <div className='company'>
        <input 
        className='search' 
        type="search" 
        placeholder='회사 이름' 
        onChange={(e) => setCompany(e.target.value)} 
        value={company || ''}
        style={{
          width: '100%',
          padding: '5px'
        }}
        ></input>
        <button onClick={fetchCompanies}>검색</button>
      </div>
      <table className='company-table'>
        <tr className='head'>
          <td>ID</td>
          <td>회사이름</td>
        </tr>
        {companyInfo && companyInfo.map((data, index) => (
          <tr key={index} onClick={() => { setSelectCompany(data); setModalOpen(false) }}>
            <td>{data.id}</td>
            <td>{data.company_name}</td>
          </tr>
        ))}

      </table>
    </div>
  );

  if (loginStatus === 'no api') {
    navigate("/registoration", {state: {username: username}});
    setLoginStatus(null);
  } else {
    return (
      <div className='login-frame'>
        {modalOpen && openSearch()}
        <img src={topMainLogo} alt="Top Menu Logo" className="top-menu-logo" />
        <div className='login'>
          <div className='tab'>
            <button
              style={{ backgroundColor: counselorMode ? '#ccc' : 'white' }}
              onClick={() => setCounselorMode(false)}
            >
              관리자 로그인
            </button>
            <button
              style={{ backgroundColor: counselorMode ? 'white' : '#ccc' }}
              onClick={() => setCounselorMode(true)}
            >
              상담사 로그인
            </button>
          </div>
          {counselorMode && (
            <div className='company'>
              <input type="search" placeholder='회사 이름' onChange={(e) => setCompany(e.target.value)} value={selectCompany.company_name}></input>
              <button onClick={() => { setModalOpen(true); fetchCompanies(); }}>검색</button>
            </div>
          )}
          <input
            type="text"
            placeholder="사용자명"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <br />
          <input
            type="password"
            placeholder="비밀번호"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <button onClick={handleLogin}>로그인</button>
          {loginStatus === false && (<p>로그인에 실패했습니다. 다시 시도해주세요.</p>)}
        </div>
      </div>
    );
  }

};
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<Root />);

reportWebVitals();
